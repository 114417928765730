import {
  Facet,
  Paging,
  PagingInfo,
  Results,
  SearchBox,
  SearchProvider,
  Sorting,
  WithSearch,
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import React from "react";

import SearchIcon from "./components/SearchIcon";
import CheckboxFacet from "./components/filter/CheckboxFacet";
import PageIndicator from "./components/paging/PageIndicator";
import SearchResults from "./components/search/Results";
import { buildSortOptionsFromConfig } from "./helpers/config-helpers";
import { searchConfig } from "./helpers/search-config";
import "./styles/checkbox.scss";
import "./styles/globals.scss";
import "./styles/layout.scss";
import "./styles/paging.scss";
import "./styles/searchbar.scss";

const config = (domain: string, default_app: boolean) => {
  return {
    ...searchConfig(domain, default_app),
    searchQuery: {
      ...searchConfig(domain, default_app).searchQuery,
      disjunctiveFacets: ["domain", "type", "categories"],
      filters: default_app
        ? undefined
        : [{ field: "domain", type: "any" as const, values: [domain] }],
    },
    alwaysSearchOnInitialLoad: true,
  };
};

export default function App() {
  const rootEl = document.querySelector("#appsearch") as HTMLElement;
  const domain: string = rootEl.dataset.domain ?? "dfo.no";
  const default_app: boolean = domain === "dfo.no";

  return (
    <SearchProvider config={config(domain, default_app)}>
      <WithSearch
        mapContextToProps={({ wasSearched, filters }) => ({
          wasSearched,
          filters,
        })}
      >
        {({ wasSearched }) => {
          return (
            <Layout
              className="bg-background"
              header={
                <div>
                  <h1 className="twsearch-pb-1">Søk</h1>
                  <SearchBox
                    autocompleteSuggestions={true}
                    debounceLength={0}
                    inputView={({
                      getAutocomplete,
                      getInputProps,
                      getButtonProps,
                    }) => (
                      <>
                        <div className="sui-search-box__wrapper">
                          <input
                            {...getInputProps({
                              placeholder: "Søk",
                              spellCheck: false,
                            })}
                          />
                          {getAutocomplete()}

                          <button aria-label="Søk" {...getButtonProps()}>
                            <SearchIcon />
                          </button>
                        </div>
                      </>
                    )}
                    onSelectAutocomplete={(
                      selection: { suggestion: string },
                      {},
                      defaultOnSelectAutocomplete: any,
                    ) => defaultOnSelectAutocomplete(selection)}
                  />
                </div>
              }
              bodyContent={
                <Results resultView={SearchResults} shouldTrackClickThrough />
              }
              bodyHeader={
                wasSearched && (
                  <PagingInfo
                    view={({ totalResults }) => (
                      <h2 className="twsearch-pb-2 md:twsearch-pb-7">
                        {totalResults} treff funnet
                      </h2>
                    )}
                  />
                )
              }
              sideContent={
                <>
                  {wasSearched && (
                    <>
                      <h2 className="desktop twsearch-pb-2">Sorter</h2>
                      <h2 className="mobile twsearch-pt-7">Sorter</h2>
                      <Sorting sortOptions={buildSortOptionsFromConfig()} />
                    </>
                  )}
                  <h2 className="desktop twsearch-pb-2 twsearch-pt-7">
                    Filter
                  </h2>
                  <h2 className="mobile twsearch-pt-7">Filter</h2>

                  <Facet
                    field={"type"}
                    label="Type"
                    filterType="any"
                    view={CheckboxFacet}
                  />
                  <Facet
                    field={"categories"}
                    label="Kategori"
                    filterType="any"
                    view={CheckboxFacet}
                  />
                  {default_app && (
                    <Facet
                      field="domain"
                      filterType="any"
                      label="Domene"
                      view={CheckboxFacet}
                    />
                  )}
                </>
              }
              bodyFooter={
                <Paging
                  view={({ current, onChange, resultsPerPage, totalPages }) => (
                    <PageIndicator
                      current={current}
                      onChange={onChange}
                      resultsPerPage={resultsPerPage}
                      totalPages={totalPages}
                    />
                  )}
                />
              }
            />
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}

/*
{resultSearchTerm ? (
  <button onClick={() => setSearchTerm("")}>
    <CloseIcon />
  </button>
) : (  )}*/
