import {
  SearchBox,
  SearchProvider,
  WithSearch,
} from "@elastic/react-search-ui";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import React from "react";

import SearchIcon from "./components/SearchIcon";
import { searchConfig } from "./helpers/search-config";
import "./styles/globals.scss";
import "./styles/searchbar.scss";

const config = (domain: string, default_app: boolean) => {
  return {
    ...searchConfig(domain, default_app),
    searchQuery: {
      ...searchConfig(domain, default_app).searchQuery,
      disjunctiveFacets: ["domain", "type"],
    },
    alwaysSearchOnInitialLoad: false,
  };
};

export default function Header() {
  const headerEl = document.querySelector("#appsearch-header") as HTMLElement;
  const domain: string = headerEl.dataset.domain ?? "dfo.no";

  const default_app: boolean = domain === "dfo.no";

  return (
    <SearchProvider config={config(domain, default_app)}>
      <WithSearch
        mapContextToProps={({ wasSearched }) => ({
          wasSearched,
        })}
      >
        {() => {
          return (
            <div className="custom-header-layout">
              <div className="custom-header-layout__inner ">
                <SearchBox
                  autocompleteSuggestions={true}
                  debounceLength={0}
                  inputProps={{
                    placeholder: "Søk",
                    autoFocus: true,
                    "aria-label": "Søk",
                    spellCheck: false,
                  }}
                  inputView={({
                    getAutocomplete,
                    getInputProps,
                    getButtonProps,
                  }) => (
                    <>
                      <div className="sui-search-box__wrapper">
                        <input
                          {...getInputProps({
                            placeholder: "Søk",
                          })}
                        />
                        {getAutocomplete()}
                        <button aria-label="Søk" {...getButtonProps()}>
                          <SearchIcon />
                        </button>
                      </div>
                    </>
                  )}
                  onSelectAutocomplete={(
                    selection: { suggestion: string },
                    {},
                    defaultOnSelectAutocomplete: any,
                  ) => {
                    if (selection?.suggestion) {
                      window.location.href = `${window.location.origin}/sok?q=${selection.suggestion}`;
                    } else {
                      defaultOnSelectAutocomplete(selection);
                    }
                  }}
                  onSubmit={(searchTerm) => {
                    window.location.href = `${window.location.origin}/sok?q=${searchTerm}`;
                  }}
                />
              </div>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}
