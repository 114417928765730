import React, { useEffect, useRef, useState } from "react";

export interface IPaginationProps {
  current?: number;
  onChange?: (page: number) => void;
  resultsPerPage?: number;
  totalPages?: number;
}

const itemsToDisplay = (active: number, totalPages: number) => {
  const maxButtons = 3;
  const items = [];
  let item: any = {};
  let previousItem: any = {};
  const maxPage = totalPages;
  const padSup = Math.floor((maxButtons - 1) / 2);
  const modulo = (maxButtons - 1) % 2;
  const padInf = padSup + modulo;
  for (let j = 0; j < maxPage; j++) {
    item = {};
    if (
      j === 0 ||
      j === maxPage - 1 ||
      j === active - 1 ||
      j === active - 2 ||
      (active === 1 && j === 1) ||
      (active - padInf < j && j < active + padSup)
    ) {
      item.display = "display";
    } else if (previousItem.display === "disabled") {
      item.display = "hidden";
    } else {
      item.display = "disabled";
    }
    items.push(item);
    previousItem = { ...item };
    if (item.display === "hidden") {
      previousItem.display = "disabled";
    }
  }
  return items;
};

const PageIndicator = (props: IPaginationProps) => {
  const { current = 1, totalPages, resultsPerPage, onChange } = props;
  const [currentPage, setCurrentPage] = useState(current);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const updateActivePage = (activePage: number) => () => {
    setCurrentPage(activePage);
    if (onChange) {
      onChange(activePage);
    }
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
    if (onChange) {
      onChange(currentPage - 1);
    }
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    if (onChange) {
      onChange(currentPage + 1);
    }
  };

  const displayPaginationItem = (i: number, active: number) => {
    return (
      <button
        className={i + 1 === active ? "selected" : ""}
        onClick={updateActivePage(i + 1)}
        disabled={active === i + 1}
        key={i}
      >
        {i + 1}
      </button>
    );
  };

  useEffect(() => {
    handleScrollToTop();
  }, [current]);

  return (
    <div className="search-app-paging-list">
      <button
        aria-label="Forrige side"
        className="search-app-paging-indicator"
        disabled={currentPage <= 1}
        onClick={previousPage}
      />
      <div className="search-app-paging-indicator-list">
        {itemsToDisplay(currentPage, totalPages!).map((paginationItem, i) =>
          paginationItem.display === "display" ? (
            displayPaginationItem(i, currentPage)
          ) : paginationItem.display === "disabled" ? (
            <button disabled key={i}>
              <span>...</span>
            </button>
          ) : null,
        )}
      </div>
      <button
        aria-label="Neste side"
        className="search-app-paging-indicator inverse"
        disabled={currentPage >= totalPages!}
        onClick={nextPage}
      />
    </div>
  );
};

export default PageIndicator;
