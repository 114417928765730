import AppSearchApiConnector from "@elastic/search-ui-app-search-connector";

import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  getConfig,
} from "./config-helpers";

// import { isItemPresent } from "./utils";

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();

export const connector = new AppSearchApiConnector({
  searchKey: process.env["REACT_APP_SEARCH_KEY"] || searchKey,
  engineName: process.env["REACT_APP_SEARCH_ENGINE_NAME"] || engineName,
  hostIdentifier,
  endpointBase: process.env["REACT_APP_SEARCH_ENDPOINT_BASE"] || endpointBase,
});

export const searchConfig = (domain: string, default_app: boolean) => {
  return {
    /* onSearch: (requestState: any, queryConfig: any, next: any) => {
      let updatedState = requestState;

      if (isItemPresent(updatedState.filters, "Nyhet")) {
        updatedState = {
          ...requestState,
          sortList: [{ field: "published_at", direction: "desc" }],
        };
      }
      return next(updatedState, queryConfig);
    }, */
    searchQuery: {
      facets: buildFacetConfigFromConfig(),
      ...buildSearchOptionsFromConfig(),
      search_fields: {
        title: {
          weight: 5,
        },
        description: {
          weight: 3,
        },
        categories: {
          weight: 2,
        },
        body_content: {
          weight: 1,
        },
      },
      result_fields: {
        title: {
          snippet: {
            size: 100,
            fallback: true,
          },
        },
        description: {
          snippet: {
            size: 500,
            fallback: true,
          },
        },
        domain: {
          raw: {},
        },
        type: {
          raw: {},
        },
        categories: {
          raw: {},
        },
        tags: {
          raw: {},
        },
        url: {
          raw: {},
        },
        published_at: {
          raw: {},
        },
      },
    },
    autocompleteQuery: buildAutocompleteQueryConfig(),
    apiConnector: connector,
    debug: false,
    initialState: default_app
      ? {
          resultsPerPage: 10,
        }
      : {
          resultsPerPage: 10,
          filters: [
            { field: "domain", type: "any" as const, values: [domain] },
          ],
        },
  };
};
