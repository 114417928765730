import React from "react";

import convertDomainToText from "helpers/convertDomainToText";

interface ICardProps {
  categories?: string[];
  description: string;
  domain: string;
  id: string;
  tags?: string[];
  title: string;
  type: string;
  url: string;
  published_at: string;
}

const Card = ({
  title,
  description,
  url,
  type,
  domain,
  categories = [],
  tags = [],
  published_at,
}: ICardProps) => {
  const breadcrumbs: string[] = [type, ...categories, ...tags];
  return (
    <li>
      <a
        className="twsearch-mb-4 twsearch-flex twsearch-flex-col twsearch-border-2 twsearch-border-solid twsearch-border-border-grey twsearch-bg-white hover:twsearch-bg-card-hover hover:twsearch-border-primary"
        href={url}
      >
        <div className="twsearch-flex twsearch-flex-col twsearch-py-6 md:twsearch-px-7 twsearch-px-6 twsearch-text-start">
          <h3
            className="card-title"
            dangerouslySetInnerHTML={{ __html: title }}
          ></h3>
          <p
            className="twsearch-pt-2"
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
          {/* <p
            className="twsearch-pt-2 twsearch-bg-amber-500"
            dangerouslySetInnerHTML={{ __html: published_at }}
          ></p> */}
          <div className="twsearch-flex twsearch-gap-4 twsearch-pt-3.5 twsearch-flex-wrap">
            {breadcrumbs.map((item: string, i: number) => {
              return (
                <span
                  className={`twsearch-rounded-3xl twsearch-px-3.5 twsearch-py-1.5 ${
                    i === 0
                      ? "twsearch-bg-light-blue"
                      : "twsearch-bg-supportive-green"
                  }`}
                  key={item}
                >
                  {item}
                </span>
              );
            })}
            <span className="twsearch-py-1.5 twsearch-text-primary twsearch-font-bold">
              {convertDomainToText(domain)}
            </span>
          </div>
        </div>
      </a>
    </li>
  );
};

export default Card;
