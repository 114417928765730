import React from "react";

interface CheckBoxProps {
  count: number;
  label: string;
  name: string;
  onRemove: (name: string) => void;
  onSelect: (name: string) => void;
  selected: boolean;
}

const CheckBox = ({
  name,
  selected,
  count,
  onRemove,
  onSelect,
}: CheckBoxProps) => {
  return (
    <div
      className={`custom-checkbox-styles twsearch-flex twsearch-cursor-pointer twsearch-items-center ${
        count > 0 ? "count" : "no-count"
      }`}
    >
      <input
        checked={selected}
        className="checkbox twsearch-h-6 twsearch-w-6 twsearch-cursor-pointer outline outline-2 outline-primary-light"
        id={name}
        name={name}
        onChange={() => (selected ? onRemove(name) : onSelect(name))}
        type="checkbox"
      />
      <span
        className="checkmark"
        onClick={() => (selected ? onRemove(name) : onSelect(name))}
      />
      <label className="twsearch-ml-7 twsearch-cursor-pointer" htmlFor={name}>
        {name}
      </label>
    </div>
  );
};

export default CheckBox;
