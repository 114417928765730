import React from "react";

const SearchIcon = () => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 19 20"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.868 19.162L12.925 12.678C14.264 11.277 15 9.445 15 7.5C15 5.497 14.22 3.613 12.803 2.197C11.386 0.781 9.503 0 7.5 0C5.497 0 3.613 0.78 2.197 2.197C0.781 3.614 0 5.497 0 7.5C0 9.503 0.78 11.387 2.197 12.803C3.614 14.219 5.497 15 7.5 15C9.226 15 10.862 14.421 12.188 13.355L18.131 19.838C18.23 19.946 18.364 20 18.5 20C18.621 20 18.742 19.957 18.838 19.869C19.042 19.682 19.055 19.366 18.869 19.163L18.868 19.162ZM0.999 7.5C0.999 3.916 3.915 1 7.499 1C11.083 1 13.999 3.916 13.999 7.5C13.999 11.084 11.083 14 7.499 14C3.915 14 0.999 11.084 0.999 7.5Z"
        fill="#012A4C"
      />
    </svg>
  );
};

export default SearchIcon;
