import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import Header from "./Header";

let container: HTMLElement;

if (document.getElementById("appsearch")) {
  container = document.getElementById("appsearch")!;
  createRoot(container).render(<App />);
}
if (document.getElementById("appsearch-header")) {
  container = document.getElementById("appsearch-header")!;
  createRoot(container!).render(<Header />);
}
