import { ResultViewProps } from "@elastic/react-search-ui-views";
import React from "react";

import Card from "./Card";

const SearchResults = (props: ResultViewProps) => {
  const {
    description: { snippet: description } = { snippet: "" },
    title: { snippet: title } = { snippet: "" },
    url: { raw: url } = { raw: "" },
    domain: { raw: domain } = { raw: "" },
    id: { raw: id } = { raw: "" },
    type: { raw: type } = { raw: "Uten type" },
    categories: { raw: categories } = { raw: [] },
    tags: { raw: tags } = { raw: [] },
    published_at: { raw: published_at } = { raw: [] },
  } = props.result;

  return (
    <Card
      categories={categories}
      description={description}
      domain={domain}
      id={id}
      key={id}
      tags={tags}
      title={title}
      type={type}
      url={url}
      published_at={published_at}
    />
  );
};

export default SearchResults;
