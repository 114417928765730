import { FacetViewProps } from "@elastic/react-search-ui-views";
import React from "react";

import type { FieldValue } from "@elastic/search-ui";

import CheckBox from "./CheckBox";

function CheckboxFacet({ label, onRemove, onSelect, options }: FacetViewProps) {
  return (
    <div className="twsearch-flex twsearch-flex-col twsearch-items-start twsearch-py-4 md:twsearch-flex-grow md:twsearch-py-3">
      <h3 className="text-primary-dark twsearch-pb-2.5">{label}</h3>
      <ul className="twsearch-flex twsearch-flex-col">
        {options.length < 1 && <div>No matching options</div>}
        {options.map((option) => {
          const checked = option.selected;
          const value = option.value as FieldValue;
          return (
            <li className="twsearch-py-1" key={value as string}>
              <CheckBox
                count={option.count}
                label={label}
                name={value as string}
                onRemove={onRemove}
                onSelect={onSelect}
                selected={checked ?? false}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default CheckboxFacet;
