export default function convertDomainToText(domain: string): string {
  switch (domain) {
    case process.env["REACT_APP_ARBEIDSGIVER"]:
      return "Statens arbeidsgiverportal";
    case process.env["REACT_APP_ANS"]:
      return "Anskaffelser";
    case process.env["REACT_APP_OKSTAT"]:
      return "Okstat";
    case process.env["REACT_APP_DFO"]:
      return "DFO";
    default:
      return domain;
  }
}
